import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router";
import { useParams } from "react-router-dom";
import CuetLoader from "../component/Loader/Loader";
import { API, DEFAULTUSER, PARENTAPI } from "./constants";

const PrivateRoutes = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { subject, topic, subTopic, id } = useParams();

  useEffect(() => {
    localStorage.removeItem("timeTaken");
    localStorage.removeItem("optionsUI");
    localStorage.removeItem("questionStatus");
    localStorage.removeItem("timeTaken");
    localStorage.removeItem("user");
    localStorage.removeItem("timeForEachQues");

    const fetchUser = async () => {
      if (id === "free" && subTopic === "1" && topic === "mock_test") {
        localStorage.setItem("user", JSON.stringify(DEFAULTUSER));

        setIsAuthorized(true);
        setIsLoading(false);
        return;
      } else {
        if (id !== "free") {
          try {
            const response = await axios.get(`${API}/users/find/${id}`);
            // console.log("response", response);
            localStorage.setItem("user", JSON.stringify(response.data));

            setIsAuthorized(true);
            setIsLoading(false);
            // console.log("User is authorized for mock test");
          } catch (err) {
            setIsAuthorized(false);
            setIsLoading(false);
            // console.log("User not found or not authorized for mock test");
            localStorage.clear();
            window.location.href = `${PARENTAPI}/purchase`;
          }
        } else {
          localStorage.clear();
          window.location.href = `${PARENTAPI}/purchase`;
        }
      }
    };

    fetchUser();
  }, [id, subTopic, subject, topic]);

  if (isLoading) {
    return <CuetLoader />;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/notfound" />;
};

export default PrivateRoutes;
