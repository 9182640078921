export const API = "https://api.testknock.com/api";
export const USERAPI = "https://testknock-questions.onrender.com/api";
export const PARENTAPI = "https://cuet.testknock.com";
export const DEFAULTUSER = {
  subject: {
    math: { topics: [] },
    lr: { topics: [] },
    varc: { topics: [] },
    di: { topics: [] },
  },
  subject_progress: {
    math: [],
    lr: [],
    varc: [],
    di: [],
  },
  social: {
    github: "",
    linkedin: "",
    portfolio: "",
  },
  _id: "6553c02f67bc141365a64a6c",
  email: "user123@gmail.com",
  name: "User",
  profilePic: "",
  isAdmin: true,
  branch: "",
  year: "",
  contact: "",
  accessLevel: "",
  institute: "",
  last_activity: [],
  createdAt: "2023-11-14T18:45:03.907Z",
  updatedAt: "2024-02-24T20:21:35.959Z",
  __v: 0,
};

export const subtopicsData = {
  topic: [
    "Spot the Error",
    "Sentence Correction",
    "Fill in the Blanks - Single",
    "Fill in the Blanks - Double",
    "Cloze Test",
    "Synonyms",
    "Antonyms",
    "One Word Substitution",
    "Idioms & Phrases",
    "Miscellaneous",
    "Jumbled Sentences & Parajumble",
    "Reading Comprehension",
    "Number System",
    "Algebra",
    "Simplification & Calculation",
    "Average",
    "Percentage",
    "Fraction & Decimals,Square root",
    "Ratio & Proportion",
    "Profit & Loss",
    "S.I & C.I",
    "Partnership",
    "Time, Speed & Distance",
    "Time & Work",
    "Mensuration",
    "Geometry",
    "Permutation & Combination",
    "Probability",
    "General Awarness",
    "General English 1",
    "General English 2",
    "General English 3",
    "General English 4",
    "General English 5",
    "General English 6",
    "General English 7",
    "General English 8",
    "General English 9",
    "General English 10",
    "General English 11",
    "General English 12",
    "General Test 1",
    "General Test 2",
    "General Test 3",
    "General Test 4",
  ],
  subTopic: [
    "Subject Verb Agreement",
    "Modifiers",
    "Parallel Construction",
    "Synonyms",
    "Antonyms",
    "One Word Substitution",
    "Idioms and Phrases",
    "Art & Culture",
    "Indian Economics",
    "Indian Polity",
    "Indian Geography",
    "Indian History",
    "General Science",
    "International Organization",
    "World History",
    "Award and Honor",
  ],
};

export const NEW_API = [
  {
    _id: "65d5edcb5623f3321344fb3e",
    questionTextAndImages: [
      {
        text: [""],
        image: "",
        _id: "65d5edcb5623f3321344fb3f",
      },
    ],
    description: [],
    difficulty: "",
    topic: "Spot_the_Error",
    subTopic: "",
    entranceExam: ["CUET"],
    subQuestions: [
      {
        questionTextAndImages: [
          {
            text: [
              "Another change that (a) / I notice in her is that she (b) / avoids to speak to me. (c) / No error (d)",
            ],
            image: "",
            _id: "65d5edcb5623f3321344fb41",
          },
        ],
        options: [
          {
            text: "A",
            image: "",
            _id: "65d5edcb5623f3321344fb42",
          },
          {
            text: "B",
            image: "",
            _id: "65d5edcb5623f3321344fb43",
          },
          {
            text: "C",
            image: "",
            _id: "65d5edcb5623f3321344fb44",
          },
          {
            text: "D",
            image: "",
            _id: "65d5edcb5623f3321344fb45",
          },
        ],
        correctOptionIndex: 0,
        explanation: [
          {
            text: ["Remove will. Do not use will after time clause."],
            image: "",
            _id: "65d5edcb5623f3321344fb46",
          },
        ],
        difficulty: "",
        positiveMarks: null,
        negativeMarks: null,
        _id: "65d5edcb5623f3321344fb40",
      },
    ],
    createdAt: "2024-02-21T12:34:19.319Z",
    updatedAt: "2024-02-21T12:34:19.319Z",
    __v: 0,
  },
];
